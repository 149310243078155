import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import Helmet from "react-helmet";
import Img from "gatsby-image";
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';

const CatTemplate = ({ data }) => (
    <Layout>
      <SEO
        title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      />
      <Helmet defer={false}>
        {data.wordpressPage.acf.category_bkg_img != null &&
        <style type="text/css">{`
            body {
                background-image: url(`+data.wordpressPage.acf.category_bkg_img.localFile.publicURL+`);
                background-size: cover;
                background-position: top center;
            }
        `}</style>
          }
      </Helmet>
    <header className="mainHead portfolioHead flexContainer faCenter fjCenter" css={css`
        background: ${data.wordpressPage.acf.brand_color};
    `}>      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to={`/${data.wordpressPage.parent_element.slug}/`}>
            <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: data.wordpressPage.parent_element.title}}/>
          </Link>
          
          {/* Nav Side */}

             
          <Navlinks />
        </div>
    </header>
    <section id="category-content" className="flexContainer secondaryTab">
      <div className="fCol12 maxTablet">
        <div className="flexContainer categoryInner">
        <h1><span className={data.wordpressPage.acf.category_icon+' catIcon'}><span className="path1"/><span className="path2"/><span className="path3"/><span className="path4"/><span className="path5"/><span className="path6"/><span className="path7"/></span><span className="catName">{data.wordpressPage.acf.category_name}</span></h1>
        {data.allWordpressPage.edges.map(brand =>(
          <React.Fragment>
            <a className="fCol12 catBrand flexContainer" href={brand.node.path}>
              {brand.node.acf.brand_logo !=null &&
              <img className="brandLogo fCol4" src={brand.node.acf.brand_logo.localFile.childImageSharp.sizes.src}/>
              }
              <div className="brandName fCol8" dangerouslySetInnerHTML={{ __html: brand.node.acf.brand_name}}/>
            </a>
          </React.Fragment>
        ))}

        <h3 className="csHeader fCol12" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.category_coming_soon_header}}/>
        <div className="csContent fCol12" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.category_coming_soon}}/>

        {data.allWordpressPage.edges.length < 2 &&
          <div className="fCol12 catBrand flexContainer empty"></div>
        }
        </div>
      </div>
    </section>
    </Layout>
  )
  
  export default CatTemplate
  
  export const query = graphql`
    query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
        title
        excerpt
        content
        slug
        parent_element {
          title
          slug
          parent_element {
            title
            slug
          }
        }
        acf {
          category_name
          category_bkg_img {
            localFile {
              publicURL
            }
          }
          category_coming_soon_header
          category_coming_soon
          category_icon
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
      }
      allWordpressPage(sort: {fields: menu_order, order: ASC},filter: {wordpress_parent: {eq: $id}}) {
        edges {
          node {
            id
            title
            acf {
              brand_color
              brand_name
              brand_logo {
                localFile {
                  childImageSharp {
                    sizes(maxWidth:400) {
                      src
                    }
                  }
                }
              }
            }
            slug
            excerpt
            path
            menu_order
          }
        }
      }
    }
`